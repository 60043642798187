import { effect, inject, Signal, signal, untracked } from '@angular/core';
import { FormGroup, FormResetEvent, FormSubmittedEvent, ValueChangeEvent } from '@angular/forms';
import { SignalStoreFeature, signalStoreFeature, withComputed, withHooks, WritableStateSource } from '@ngrx/signals';
import { SignalStoreFeatureResult, StateSignals } from '@ngrx/signals/src/signal-store-models';
import { Prettify } from '@ngrx/signals/src/ts-helpers';
import { DefaultFormOptions } from './DefaultFormOptions';
import { FormOptions } from './FormOptions';

type WithFormComputedDictionary<TName extends string, TData = unknown> = {
  [K in TName]: Signal<TData>;
};

export function withForm<TName extends string, Input extends SignalStoreFeatureResult, TForm extends FormGroup>(
  name: TName,
  factory: (
    store: Prettify<StateSignals<Input['state']> & Input['props'] & Input['methods'] & WritableStateSource<Prettify<Input['state']>>>,
  ) => TForm,
  options:
    | Partial<FormOptions>
    | ((
        store: Prettify<StateSignals<Input['state']> & Input['props'] & Input['methods'] & WritableStateSource<Prettify<Input['state']>>>,
      ) => Partial<FormOptions>) = {},
): SignalStoreFeature<
  Input,
  {
    state: {};
    props: WithFormComputedDictionary<TName, TForm>;
    methods: {};
  }
> {
  return signalStoreFeature(
    withComputed((store) => {
      const formState = signal<TForm>(factory(store as any));
      return {
        [name]: formState.asReadonly(),
      } as {
        [K in TName]: Signal<TForm>;
      };
    }),
    withHooks((store) => {
      const opts: FormOptions = {
        ...inject(DefaultFormOptions),
        ...inject(FormOptions),
        ...(typeof options === 'function' ? options(store as any) : options),
      };
      return {
        onInit() {
          effect(() => {
            const disabled = opts.disabled();
            const form = untracked(store[name]);
            if (disabled && form.enabled) {
              form.disable();
            } else if (!disabled && form.disabled) {
              form.enable();
            }
          });
          effect(
            (onCleanup) => {
              const form = store[name]();
              const sub = form.events.subscribe((event) => {
                if (event instanceof FormSubmittedEvent) {
                  opts.onFormSubmitted(event, form);
                } else if (event instanceof FormResetEvent) {
                  opts.onFormReset(event, form);
                } else if (event instanceof ValueChangeEvent) {
                  opts.onFormValueChange(event, form);
                }
              });
              onCleanup(() => {
                sub.unsubscribe();
              });
            },
            { allowSignalWrites: true },
          );
        },
      };
    }),
  );
}
