import { signal, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { signalStoreFeature, withComputed, WritableStateSource } from '@ngrx/signals';
import { SignalsDictionary, SignalStoreFeature, SignalStoreFeatureResult, StateSignals } from '@ngrx/signals/src/signal-store-models';
import { Prettify } from '@ngrx/signals/src/ts-helpers';
import { Observable, Subject } from 'rxjs';

type WithEventEmitterComputedDictionary<TName extends string, TData = unknown> = {
  [K in TName]: Signal<TData>;
};

export function withEventEmitter<TName extends string, Input extends SignalStoreFeatureResult, TEvent>(
  name: TName,
  factory: (
    store: Prettify<StateSignals<Input['state']> & Input['props'] & Input['methods'] & WritableStateSource<Prettify<Input['state']>>>,
  ) => Observable<TEvent>,
): SignalStoreFeature<
  Input,
  {
    state: {};
    props: WithEventEmitterComputedDictionary<TName, Observable<TEvent>>;
    methods: {};
  }
> {
  return signalStoreFeature(
    withComputed((store) => {
      const subject = new Subject<TEvent>();
      if (factory) {
        factory(store as any)
          .pipe(takeUntilDestroyed())
          .subscribe(subject);
      }
      return {
        [name]: signal(subject),
      } as any as WithEventEmitterComputedDictionary<TName, Observable<TEvent>>;
    }),
  );
}
